<template>
  <div class="forgetPassword-whoel">
    <Nav></Nav>
    <div class="content">
      <div class="head">
        <img src="" alt="" />
        您的位置：<span>首页 </span> >> 找回密码
      </div>
      <div class="con">
        <div class="title">用绑定的手机号码找回密码</div>
        <div class="input">
          <div class="step-one" v-if="step == 1">
            <div class="code-img">
              <span>图形验证码：</span>
              <img :src="codeImg" alt="" class="yan-img" @click="refreshCode" />
              <el-input v-model="imgCode" clearable></el-input>
              <el-button type="primary" @click="refreshCode()"
                >刷新校验码</el-button
              >
            </div>
            <div class="input-line">
              <span>手机号：</span>
              <el-input v-model="mobile" clearable></el-input>
              <el-button type="primary" @click="onGetMobile()"
                >获取验证码</el-button
              >
              <i>*填写手机号码和图形验证码后点击“获取验证码”获取短信验证码</i>
            </div>
            <div class="input-line">
              <span>短信验证码：</span>
              <el-input v-model="mobileCode" clearable></el-input>
            </div>
            <div class="btn">
              <el-button type="primary" @click="onStep">下一步</el-button>
            </div>
          </div>
          <div class="step-two" v-if="step == 2">
            <div class="input-line">
              <span>新密码：</span>
              <el-input
                v-model="newPassWord"
                show-password
                placeholder="请输入密码"
                @blur="onRegClick"
              ></el-input>
            </div>
            <div class="input-line">
              <span>重复新密码：</span>
              <el-input v-model="repeat" clearable></el-input>
            </div>
            <div class="btn">
              <el-button type="primary" @click="onReset">修改密码</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import RDUtils from "@/api/RDUtils";
import SHA1 from "js-sha1";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";
export default {
  name: "ForgetPassword",
  components: { Nav, Bottom },
  data() {
    return {
      mobile: "",
      mobileCode: "",
      imgCode: "", //图形验证码
      repeat: "",
      newPassWord: "",
      codeImg: "",
      step: 1,
    };
  },
  created() {
    this.reload();
    this.refreshCode();
  },
  methods: {
    reload() {},
    refreshCode() {
      var thiz = this;
      thiz.codeImg = RD.user().icodeImage_URL() + "?" + new Date().getTime();
    },
    onGetMobile() {
      var thiz = this;
      if (thiz.mobile == "") {
        thiz.$message("手机号不能为空");
        return;
      }
      if (thiz.imgCode == "") {
        thiz.$message("图形验证码不能为空");
        return;
      }
      RD.user()
        .requestSMSForResetPassword(thiz.mobile, thiz.imgCode)
        .then(() => {
          thiz.$message("验证码已发送，请在10分钟的有效时间内完成验证");
          thiz.oneShow = false;
          thiz.passShow = true;
        });
    },
    // 下一步按钮
    onStep() {
      var thiz = this;
      if (thiz.mobileCode == "") {
        thiz.$message("短信验证码不能为空");
        return;
      }
      RD.pure()
        .user()
        .findBackPassword(thiz.mobileCode)
        .then(() => {
          thiz.step = 2;
        })
        .catch((error) => {
          this.$alert(error.msg, "提示", {
            confirmButtonText: "确定",
            type: "warning",
          })
            .then(() => {})
            .catch(() => {});
        });
    },
    //   重置密码
    onReset() {
      var thiz = this;
      if (thiz.newPassWord == "") {
        thiz.$message("新密码不能为空");
        return;
      }
      if (thiz.newPassWord != thiz.repeat) {
        thiz.$message("新密码两次输入不一致");
        return;
      }
      if (
        thiz.newPassWord != "" &&
        !RDUtils.checkWeekPassword(thiz.newPassWord)
      ) {
        alert("新密码应至少8位，包含数字，大写和小写之中的至少两种");
        return;
      }
      RD.user()
        .resetPasswordAfterConfirm(SHA1(thiz.newPassWord))
        .then(() => {
          thiz.$message("修改密码成功");
          thiz.$router.push("/index.html");
        });
    },
    // 验证强密码
    onRegClick() {
      var thiz = this;
      if (
        thiz.newPassWord != "" &&
        !RDUtils.checkWeekPassword(thiz.newPassWord)
      ) {
        alert("新密码应至少8位，包含数字，大写和小写之中的至少两种");
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.forgetPassword-whoel {
  width: 100%;
  .content {
    width: 120rem;
    margin: 0 auto;
    min-height: calc(100vh - 47.89rem);
    .head {
      height: 40px;
      background-color: #dfdfdf;
      line-height: 40px;
      white-space: nowrap;
      text-align: left;
      clear: both;
      display: flex;
      font-size: 14px;
      img {
        width: 15px;
      }
      span {
        color: red;
      }
    }
    .con {
      background-color: #efefef;
      padding: 10px;
      .title {
        height: 80px;
        line-height: 80px;
        font-weight: bold;
        font-size: 18px;
        margin: 0px 10px 0 50px;
        text-align: left;
      }
      .code-img {
        text-align: left;
        font-size: 16px;
        line-height: 50px;
        span {
          display: inline-block;
          width: 100px;
          height: 50px;
          margin: 0 20px 0 50px;
          text-align: right;
        }
        img {
          vertical-align: middle;
          margin-right: 10px;
        }
        .el-input {
          width: 109px;
        }
        .el-button {
          margin-left: 10px;
        }
      }
      .input {
        text-align: left;
        font-size: 16px;
        .input-line {
          // height: 50px;
          line-height: 50px;
          span {
            display: inline-block;
            width: 100px;
            height: 50px;
            margin: 0 20px 0 50px;
            text-align: right;
          }
          .el-input {
            width: 204px;
          }
          .el-button {
            width: 100px;
            margin-left: 10px;
          }
          i {
            font-style: normal;
            margin-left: 10px;
            line-height: 50px;
          }
        }
      }
      .btn {
        text-align: left;
        margin-left: 200px;
      }
    }
  }
}
</style>